export const availableApps = [
  {
    appLink: "https://zapier.com/apps/vext/integrations",
    id: "zapier",
    name: "Zapier",
    imgSrc: "/images/integrations/zapier.svg",
    desc: "Give your LLM pipeline access to thousands of apps via Zapier.",
    external: true,
    available: true,
    buttonText: "Connect",
  },
  {
    appLink: "https://pipedream.com/apps/vext",
    id: "pipedream",
    name: "Pipedream",
    imgSrc: "/images/integrations/pipedream.svg",
    desc: "Give your LLM pipeline access to thousands of apps via Pipedream.",
    external: true,
    available: true,
    buttonText: "Connect",
  },
  {
    id: "discord",
    name: "Discord",
    imgSrc: "/images/integrations/discord.svg",
    desc: "Add a Discord bot to your server powered by your LLM pipeline.",
    external: false,
    available: true,
    drawer: true,
  },
  {
    id: "slack",
    name: "Slack",
    imgSrc: "/images/integrations/slack.svg",
    desc: "Conncect your LLM pipeline to Slack to create a powerful chatbot.",
    external: false,
    available: true,
    drawer: true,
  },
  {
    id: "teams",
    name: "Teams",
    imgSrc: "/images/integrations/teams.svg",
    desc: "Conncect your LLM pipeline to Teams to create a powerful chatbot.",
    external: false,
    available: false,
    drawer: true,
  },
  {
    id: "fbmessenger",
    name: "Messenger",
    imgSrc: "/images/integrations/messenger.svg",
    desc: "Conncect your LLM pipeline to Messenger to create a powerful chatbot.",
    external: true,
    available: false,
  },
  {
    id: "telegram",
    name: "Telegram",
    imgSrc: "/images/integrations/telegram.svg",
    desc: "Conncect your LLM pipeline to Telegram to create a powerful chatbot.",
    external: true,
    available: false,
  },
]

export const availableData = [
  {
    appLink: "https://docs.google.com/forms/d/e/1FAIpQLScFz4wGr4Xv-YSi84HZcnQLQcWubaJSvc5HtYB0vGNLvjCtQA/viewform",
    id: "servicenow",
    name: "ServiceNow",
    imgSrc: "/images/integrations/servicenow.svg",
    desc: "Import your ServiceNow knowledge article or incident to a Data Set.",
    external: false,
    available: true,
    buttonText: "Connect",
  },
  {
    id: "confluence",
    name: "Confluence",
    imgSrc: "/images/integrations/confluence.svg",
    desc: "Import your Confluence pages from your space.",
    external: false,
    available: true,
  },
  {
    id: "google",
    name: "Google Drive",
    imgSrc: "/images/integrations/googledrive.svg",
    desc: "Import your Google Docs, Sheets, and other files.",
    external: false,
    available: true,
  },
  {
    id: "azure",
    name: "Microsoft SharePoint",
    imgSrc: "/images/integrations/azure.svg",
    desc: "Import your Microsoft documents and other files.",
    external: false,
    available: true,
  },
  {
    id: "notion",
    name: "Notion",
    imgSrc: "/images/integrations/notion.svg",
    desc: "Import your Notion pages from your account.",
    external: false,
    available: true,
  },
  // More in the future
]

export const availableLlm = [
  {
    appLink:
      "https://river-bottle-af7.notion.site/How-to-Integrate-My-AWS-SageMaker-Models-15847600424a80859de2ec24052ee25e",
    id: "sagemaker",
    name: "AWS SageMaker",
    imgSrc: "/images/integrations/awsai.svg",
    desc: "Integrate your own models and utilize them on Vext.",
    external: true,
    available: true,
    buttonText: "Learn More",
  },
  {
    id: "bedrock",
    name: "AWS Bedrock",
    imgSrc: "/images/integrations/awsai.svg",
    desc: "Integrate your LLMs hosted on Bedrock and them on Vext.",
    external: false,
    available: false,
  },
  {
    id: "huggingface",
    name: "Hugging Face",
    imgSrc: "/images/integrations/huggingface.svg",
    desc: "Link your LLM from Hugging Face Inference Endpoints to Vext.",
    external: false,
    available: false,
  },
  // More in the future
]

// TODO: deprecated constant and can be removed
export const comingsoonApps = [
  // More in the future
]

export const appCheckEndpoint = [
  { service: "azure", url: "/third_party/azure/is_need_azure_oauth" },
  { service: "google", url: "/third_party/google/is_need_google_oauth" },
  { service: "confluence", url: "/third_party/confluence/is_need_confluence_oauth" },
  { service: "notion", url: "/third_party/notion/is_need_notion_oauth" },
  { service: "discord", url: "/third_party/discord/is_need_discord_oauth" },
  { service: "slack", url: "/third_party/slack/is_need_slack_oauth" },
  { service: "teams", url: "/third_party/teams/is_need_teams_oauth" },
  { service: "servicenow", url: "/third_party/servicenow/is_need_servicenow_oauth" },
]

export const integrationsConfig = {
  azure: {
    postUrl: `/third_party/azure/oauth`,
    authUrl: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${encodeURIComponent(
      process.env.REACT_APP_MS_APP_ID,
    )}&response_type=code&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/oauth-redirect`,
    )}&response_mode=query&scope=${encodeURIComponent(
      "openid profile User.Read offline_access Sites.Read.All Sites.Selected Files.Read Files.Read.All Files.Read.Selected",
    )}&state=sharepoint`,
  },
  confluence: {
    postUrl: `/third_party/confluence/oauth`,
    authUrl: `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${encodeURIComponent(
      process.env.REACT_APP_CONFLUENCE_CLIENT_ID,
    )}&scope=${encodeURIComponent(
      "read:content:confluence read:content-details:confluence read:space-details:confluence read:page:confluence read:blogpost:confluence read:label:confluence read:user:confluence read:content.property:confluence read:content.restriction:confluence read:content.metadata:confluence read:space.property:confluence read:space:confluence offline_access",
    )}&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/oauth-redirect`,
    )}&response_type=code&state=confluence&prompt=consent`,
  },
  google: {
    postUrl: `/third_party/google/oauth`,
    authUrl: `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${process.env.REACT_APP_GOOGLE_DOC_CLIENT_ID}&redirect_uri=${window.location.origin}/oauth-redirect&response_type=code&scope=https://www.googleapis.com/auth/spreadsheets.readonly%20https://www.googleapis.com/auth/drive.readonly%20https://www.googleapis.com/auth/documents.readonly%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&access_type=offline&state=google&prompt=consent`,
  },
  notion: {
    postUrl: `/third_party/notion/oauth`,
    authUrl: `https://api.notion.com/v1/oauth/authorize?client_id=${encodeURIComponent(
      process.env.REACT_APP_NOTION_CLIENT_ID,
    )}&response_type=code&owner=user&redirect_uri=${encodeURIComponent(`${window.location.origin}/oauth-redirect`)}`,
  },
  discord: {
    postUrl: `/third_party/discord/oauth`,
    authUrl: `https://discord.com/oauth2/authorize?client_id=${encodeURIComponent(
      process.env.REACT_APP_DISCORD_CLIENT_ID,
    )}&response_type=code&scope=identify+email+guilds+&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/oauth-redirect`,
    )}`,
  },
  slack: {
    postUrl: `/third_party/slack/oauth`,
    authUrl: `https://vextapp.slack.com/oauth?client_id=${encodeURIComponent(
      process.env.REACT_APP_SLACK_CLIENT_ID,
    )}&scope=${encodeURIComponent(
      "channels:join,channels:read,channels:history,commands",
    )}&user_scope=${encodeURIComponent(
      "channels:read,groups:read,users.profile:read,users:read,users:read.email,channels:write.invites,channels:write,groups:write.invites,groups:write,mpim:write.invites,mpim:write,im:write.invites,im:write,team:read",
    )}&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/oauth-redirect`,
    )}&granular_bot_scope=1&single_channel=0&install_redirect=&tracked=1&team=1`,
  },
  teams: {
    postUrl: `/third_party/teams/oauth`,
    authUrl: `https://login.microsoftonline.com/${encodeURIComponent(
      process.env.REACT_APP_TEAMS_CLIENT_ID,
    )}/oauth2/v2.0/authorize?client_id=${encodeURIComponent(
      process.env.REACT_APP_TEAMS_APP_ID,
    )}&response_type=code&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/oauth-redirect`,
    )}&response_mode=query&scope=${encodeURIComponent(
      "email openid profile User.Read offline_access Channel.ReadBasic.All ChannelMember.Read.All ChannelMember.ReadWrite.All Group.Read.All Group.ReadWrite.All GroupMember.Read.All GroupMember.ReadWrite.All TeamMember.Read.All TeamMember.ReadWrite.All",
    )}&state=teams`,
  },
  servicenow: {
    postUrl: `/third_party/servicenow/oauth`,
    authUrl: "/third_party/servicenow/oauth",
  },
}
